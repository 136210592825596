import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64')
];

export const server_loads = [0,15];

export const dictionary = {
		"/": [~17],
		"/about-dubclub": [18],
		"/auth/complete/account": [19,[2]],
		"/auth/complete/social": [~20,[2]],
		"/auth/login": [21,[2]],
		"/auth/login/phone": [22,[2]],
		"/auth/verify": [23,[2]],
		"/auth/verify/phone": [24,[2]],
		"/capper/affiliates/links": [25,[3]],
		"/capper/affiliates/members": [26,[3]],
		"/capper/affiliates/sales": [27,[3]],
		"/capper/dashboard": [28],
		"/capper/discord/custom-bot/manage": [29,[4]],
		"/capper/discord/custom-bot/setup": [30,[4]],
		"/capper/discord/manage": [~31],
		"/capper/discord/setup": [~32],
		"/capper/manage-prices": [~33,[5],[6]],
		"/capper/products": [~34],
		"/capper/profile-v2": [~36],
		"/capper/profile-v2/edit": [~37,[7]],
		"/capper/profile-v2/edit/sections": [~38,[7]],
		"/capper/profile/[name_or_price]": [~35],
		"/capper/publish": [~39],
		"/capper/referral": [40,[8]],
		"/capper/stream/[name]": [~41,[9]],
		"/checkout/[trackinterest_id]": [~42,[10]],
		"/footer": [43],
		"/plays/[textpickKey]": [~44],
		"/plays/[textpickKey]/[viewerKey]": [~45],
		"/product/new": [46],
		"/publish/play/[capperKey]": [~47,[11]],
		"/publish/sales": [~48,[12]],
		"/r/capper": [49],
		"/r/connected-accounts": [~50],
		"/r/discover-cappers": [51,[13]],
		"/r/discover-cappers/(discovery)/cappers": [52,[13,14]],
		"/r/discover-cappers/(discovery)/leagues": [53,[13,14]],
		"/r/discover-cappers/(discovery)/plays": [54,[13,14]],
		"/r/discover-cappers/(discovery)/search": [55,[13,14]],
		"/r/discover-cappers/(discovery)/sports": [56,[13,14]],
		"/samples/[sampleKey]": [~57],
		"/samples/[sampleKey]/[viewerKey]": [~58],
		"/t/engagement/[capperKey]": [~63],
		"/t/[productKey]": [59,[15]],
		"/t/[productKey]/edit": [60],
		"/t/[productKey]/faqs": [61,[15]],
		"/t/[productKey]/notifications": [62,[15]],
		"/viewm/[marketing=lookup_key]/[user=lookup_key]": [~64,[16]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.js';